import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PlateNumberService} from 'src/app/services/platenumber.service';
import {ParkingStatus} from '../../models/parking-status';
import {ViolationService} from '../../services/violation.service';
import {PlateRecognizerResponseService} from "../../services/plate-recognizer-response.service";
import {Observable, Subject, Subscription} from "rxjs";
import {NavigationService} from "../../services/navigation.service";
import {ITown} from "../../models/town";
import {WebcamImage, WebcamInitError} from "ngx-webcam";
import {AuthenticationService} from "../../services/auth/authentication.service";
import {ImageCroppedEvent, ImageTransform} from "ngx-image-cropper";
import imageCompression from "browser-image-compression";
import {ZoneService} from "../../services/zone.service";
import {ChalkStatus} from "../../models/chalk-status";
import {ChalkService} from "../../services/chalk.service";

@Component({
  selector: 'app-check-violation',
  templateUrl: './check-violation.component.html',
  styleUrls: ['../../app.component.scss',
    './check-violation.component.css']
})
export class CheckViolationComponent implements OnInit {

  checkViolationForm: FormGroup;
  loading = false;
  chalkLoading = false;
  submitted = false;
  error;
  parkingStatus: ParkingStatus;
  chalkStatus: ChalkStatus;

  @ViewChild("checkViolationInput") checkViolationInput: ElementRef;

  isPlateRecognizerResponsePopUpVisible: boolean;
  isBarcodeRecognizerResponsePopUpVisible: boolean;
  isBarcodeClicked: boolean;
  isPlateRecognizerResponseLoading = true;
  private plateRecognizerResponseSubscription: Subscription;
  private town: ITown;

  public webcamImage: WebcamImage = null;
  private trigger: Subject<void> = new Subject<void>();
  active: boolean = false;
  croppedImage: any = '';
  transform: ImageTransform;
  availableTownZones: any = [];
  availableZoneLocations: any = [];

  selectedZone: any = null;
  selectedLocation: any = null;

  showZoneLocation: boolean = true;

  latitude: number = null;
  longitude: number = null;

  constructor(private formBuilder: FormBuilder,
              private violationService: ViolationService,
              private plateRecognizerResponseService: PlateRecognizerResponseService,
              private plateNumberService: PlateNumberService,
              private zoneService: ZoneService,
              private navigationService: NavigationService,
              private authenticationService: AuthenticationService,
              private chalkService: ChalkService,
              private cdRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.checkViolationForm = this.formBuilder.group({
      plateNumber: ['', Validators.required],
      location: [''],
      zone: ['0']
    });
    this.plateRecognizerResponseService.clearPlateRecognizerResponse();
    this.navigationService.townEmitted$.subscribe(value => {
      this.town = value;

      if (this.town != null) {
        this.getTownZones(this.town.id);
      }

    });
    this.transform = {
      scale: 1
    };
  }

  get form() {
    return this.checkViolationForm.controls;
  }

  getTownZones(townId: number) {
    this.violationService.getAvailableTownZones(townId).subscribe(value => {
      this.availableTownZones = value;
      this.showZoneLocation = value.length > 0 && value[0].zoneDescription == 'PASSPORTINC';
    });
  }

  onSubmit() {
    this.submitted = true;
    this.zoneService.setZoneNumber(this.selectedZone ? this.selectedZone.zoneNumber : '');
    this.zoneService.setZoneLocation(this.form.location.value?.length > 20
      ? this.form.location.value.slice(0, 20)
      : this.form.location.value);
    this.form.location.setValue(
      this.availableZoneLocations[0]?.length > 20
        ? this.availableZoneLocations[0].slice(0, 20)
        : this.availableZoneLocations[0]
    );

    // stop here if form is invalid or if a town doesn't have access to flowbird
    if (this.checkViolationForm.invalid || !this.isFlowbirdKioskAvailable()) {
      return;
    }
    this.clearPlateRecognizerResponseIfPlateIsChanged();
    const plateNumber = this.form.plateNumber.value;
    this.loading = true;
    let zoneId = this.selectedZone ? this.selectedZone.zoneId : '';
    this.violationService.getParkingStatusByPlateNumber(plateNumber, this.town.id, zoneId)
      .subscribe(value => {
          this.parkingStatus = value;
          this.loading = false;
          this.form.plateNumber.disable();
        },
        error => {
          this.loading = false;
        });
  }

  get headerTitle() {
    if (this.isFlowbirdKioskAvailable()) {
      return this.isLeo() ? 'Vehicle recognition' : 'Check violation';
    } else {
      return 'New violation';
    }
  }

  isLeo() {
    return this.authenticationService.isLeo();
  }

  isFlowbirdKioskAvailable() {
    return this.town?.apiUsed;
  }

  onNextVehicle() {
    this.plateRecognizerResponseService.resetNumberOfUnsuccessfulRecognizerAttempts();
    this.plateRecognizerResponseService.resetNumberOfUnsuccessfulBarcodeAttempts();
    this.resetForm();
  }

  resetForm() {
    this.checkViolationForm.reset();
    this.parkingStatus = null;
    this.selectedZone = null;
    this.selectedLocation = null;
    this.submitted = false;
    this.plateRecognizerResponseService.clearPlateRecognizerResponse();
    this.error = null;
    this.chalkStatus = null;
    this.form.zone.setValue('0');
    this.form.location.setValue('');
    this.form.plateNumber.enable();
    const input: HTMLInputElement = this.checkViolationInputElement;
    input.focus();
  }

  get checkViolationInputElement(): HTMLInputElement {
    return this.checkViolationInput.nativeElement as HTMLInputElement;
  }

  onFileChangePlate(target: any) {
    const uploadedImage = target.files.item(0);
    this.compressPlateImageIfNeeded(uploadedImage)
      .then(
        this.handleImageCompressionSuccess.bind(this),
        this.handleImageCompressionError.bind(this)
      );
    target.value = null;
  }

  onZoneChange(event: any) {
    const zoneId = event.target.value;
    if (zoneId) {
      if (zoneId === "0") {
        this.selectedZone = null;
      } else {
        this.selectedZone = this.availableTownZones.find(zone => zone.zoneId === zoneId);
        this.availableZoneLocations = [this.selectedZone.zoneName];
        this.form.location.setValue(
          this.availableZoneLocations[0]?.length > 20
            ? this.availableZoneLocations[0].slice(0, 20)
            : this.availableZoneLocations[0]
        );
      }
    }
  }

  onFileChangeBarcode(target: any) {
    const uploadedImage = target.files.item(0);
    this.compressBarcodeImageIfNeeded(uploadedImage)
      .then(
        this.handleBarcodeImageCompressionSuccess.bind(this),
        this.handleImageCompressionError.bind(this)
      );
    target.value = null;
  }

  uploadPlateFileToPlateRecognizer(formData: FormData) {
    this.error = null;
    this.plateRecognizerResponseSubscription = this.plateRecognizerResponseService.getRecognizedVehicleData(formData).subscribe(value => {
      this.isPlateRecognizerResponseLoading = false;
      if (!value) {
        this.plateRecognizerResponseService.incrementNumberOfUnsuccessfulRecognizerAttempts();
      } else {
        this.plateRecognizerResponseService.resetNumberOfUnsuccessfulRecognizerAttempts();
      }
      this.plateRecognizerResponseService.setPlateRecognizerResponse(value)
    }, error => {
      this.isPlateRecognizerResponseLoading = false;
      this.error = error;
    });
  }

  upload2DBarcodeFileToPlateRecognizer(formData: FormData) {
    this.error = null;
    this.plateRecognizerResponseSubscription = this.plateRecognizerResponseService.get2DBarcodeVehicleData(formData).subscribe(value => {
      this.isPlateRecognizerResponseLoading = false;
      if (!value) {
        this.plateRecognizerResponseService.incrementNumberOfUnsuccessfulBarcodeAttempts();
      } else {
        this.plateRecognizerResponseService.resetNumberOfUnsuccessfulBarcodeAttempts();
      }
      this.plateRecognizerResponseService.setPlateRecognizerResponse(value)
    }, error => {
      this.isPlateRecognizerResponseLoading = false;
      this.error = error;
    });
  }

  private static getLicensePlateImageFormData(file: File): FormData {
    const formData = new FormData();
    formData.append('licensePlateImage', file, file.name);
    return formData;
  }

  private static get2DBarcodeImageFormData(file: File): FormData {
    const formData = new FormData();
    formData.append('2DBarcodeImage', file, file.name);
    return formData;
  }

  hidePlateRecognizerResponsePopUp() {
    this.isPlateRecognizerResponsePopUpVisible = false;
    this.isPlateRecognizerResponseLoading = true;
    this.plateRecognizerResponseSubscription?.unsubscribe();
    this.error = null;
    const input: HTMLInputElement = this.checkViolationInputElement;
    if (!input.value) {
      input.focus();
    }
  }

  hideBarcodeRecognizerResponsePopUp() {
    this.isBarcodeRecognizerResponsePopUpVisible = false;
    this.isPlateRecognizerResponseLoading = true;
    this.plateRecognizerResponseSubscription?.unsubscribe();
    this.error = null;
    const input: HTMLInputElement = this.checkViolationInputElement;
    if (!input.value) {
      input.focus();
    }
  }

  useRecognizedPlate() {
    this.form.plateNumber.setValue(this.plateRecognizerResponseService.getPlateRecognizerResponse().plate);
    this.active = false;
    this.webcamImage = undefined;
  }

  continueWithMatchedPlate() {
    this.parkingStatus.overtimeParking = this.parkingStatus.fuzzyLogicParkingStatusCandidate.overtimeParking;
    this.parkingStatus.parkingPaid = this.parkingStatus.fuzzyLogicParkingStatusCandidate.parkingPaid;
    this.plateNumberService.setPlateNumber(this.form.plateNumber.value);
    const plateNumber = this.parkingStatus.fuzzyLogicParkingStatusCandidate.plateNumber;
    this.form.plateNumber.setValue(plateNumber);
    this.parkingStatus.fuzzyLogicParkingStatusCandidate = null;
    this.submitted = false;
  }

  isCameraRecognizerDisabled() {
    return !this.form.plateNumber.errors || this.plateRecognizerResponseService.getNumberOfUnsuccessfulRecognizerAttempts() >= 3;
  }

  isCamera2DBarcodeDisabled() {
    return !this.form.plateNumber.errors || this.plateRecognizerResponseService.getNumberOfUnsuccessfulBarcodeAttempts() >= 3;
  }

  isLocationFilled() {
    return ((this.town?.name === 'Wellesley') && this.form.location.value !== '') || !this.showZoneLocation;
  }

  prepareDataForAddViolationPage() {
    this.clearPlateRecognizerResponseIfPlateIsChanged();
    this.plateNumberService.setPlateNumber(this.form.plateNumber.value);
    this.zoneService.setZoneNumber(this.selectedZone ? this.selectedZone.zoneNumber : '0');
    this.zoneService.setZoneLocation(this.form.location.value?.length > 20
      ? this.form.location.value.slice(0, 20)
      : this.form.location.value);
    if (!this.isFlowbirdKioskAvailable()) {
      this.plateRecognizerResponseService.resetNumberOfUnsuccessfulRecognizerAttempts();
    }
  }

  onAddViolationButtonClick() {
    this.prepareDataForAddViolationPage();
  }

  onChalkButtonClick() {
    this.prepareDataForAddViolationPage();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;

        // TODO move to geo-service
        localStorage.setItem('latitude', this.getNumberAsStr(this.latitude));
        localStorage.setItem('longitude', this.getNumberAsStr(this.longitude));

        const requestBody = {
          licensePlateNumber: this.form.plateNumber.value.trim(),
          // location: this.form.location.value.trim(),
          badgeId: this.authenticationService.userValue.badgeId,
          latitude: this.getNumberAsStr(this.latitude),
          longitude: this.getNumberAsStr(this.longitude)
        }

        this.chalkLoading = true;
        this.chalkService.createNewChalkAndGetAllChalkAndViolations(this.town.id, requestBody)
          .subscribe(value => {
              this.chalkStatus = value;
              this.chalkLoading = false;
              this.form.plateNumber.disable();
            },
            error => {
              this.chalkLoading = false;
          });
      }, error => {
        console.log("Problem with user's geolocation device");
        this.chalkStatus = this.getDummyChalkStatus();
      });
    } else {
      console.log("The user did not allowed their geolocation");
      this.chalkStatus = this.getDummyChalkStatus();
    }

  }

  getDummyChalkStatus(): ChalkStatus {
    return {
      licensePlateNumber: null,
      capturedLatitude: null,
      capturedLongitude: null,
      chalkRecords: null,
      violationRecords: null
    }
  }

  getNumberAsStr(num: number) {
    return num !== null ? String(num) : ""
  }

  isPlateNumberValid() {
    return (!this.form.plateNumber.errors && !(this.availableTownZones.length > 0 && this.selectedZone === null)) && this.isLocationFilled()
  }

  isPlateNumberInvalid() {
    return (this.form.plateNumber.errors || (this.availableTownZones.length > 0 && this.selectedZone === null)) || !this.isLocationFilled()
  }

  private clearPlateRecognizerResponseIfPlateIsChanged(): void {
    const plate = this.plateRecognizerResponseService.getPlateRecognizerResponse()?.plate;
    if (this.form.plateNumber.value !== plate) {
      this.plateRecognizerResponseService.clearPlateRecognizerResponse();
    }
  }

  triggerSnapshot(): void {
    this.trigger.next();
  }

  handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  isAllowedToUsePCWebCam(): boolean {
    return this.authenticationService.userValue.technology === 'LAPTOP';
  }

  togglePlateNgxWebcam() {
    this.active = !this.active;
    this.webcamImage = undefined;
    this.isBarcodeClicked = false;
    console.log(this.isBarcodeClicked);
  }

  toggleBarcodeNgxWebcam() {
    this.active = !this.active;
    this.webcamImage = undefined;
    this.isBarcodeClicked = true;
    console.log(this.isBarcodeClicked);
  }

  public handleInitError(error: WebcamInitError): void {
    if (error.mediaStreamError && error.mediaStreamError.name === "NotAllowedError") {
      console.warn("Camera access was not allowed by user!");
    }
    this.cdRef.detectChanges();
    document.getElementById('buttonTakePhotoLeoAlt').click();
  }

  handlePlateFileInputClick() {
    this.active = false;
    this.isBarcodeClicked = false;
    console.log(this.isBarcodeClicked);
  }

  handleBarcodeFileInputClick() {
    this.active = false;
    this.isBarcodeClicked = true;
    console.log(this.isBarcodeClicked);
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  sliderInput($event: any) {
    this.transform = {
      scale: $event.target.value
    }
  }

  uploadCropped() {
    this.getCompressedCroppedFile()
      .then(
        this.handleImageCompressionSuccess.bind(this),
        this.handleImageCompressionError.bind(this)
      );
  }

  private handleImageCompressionSuccess(compressedImage) {
    let formData;
    if (this.isBarcodeClicked) {
      formData = CheckViolationComponent.get2DBarcodeImageFormData(compressedImage);
      this.upload2DBarcodeFileToPlateRecognizer(formData);
    } else {
      formData = CheckViolationComponent.getLicensePlateImageFormData(compressedImage);
      this.uploadPlateFileToPlateRecognizer(formData);
    }
  }

  private handleBarcodeImageCompressionSuccess(compressedImage) {
    const formData = CheckViolationComponent.get2DBarcodeImageFormData(compressedImage);
    this.upload2DBarcodeFileToPlateRecognizer(formData);
  }

  private handleImageCompressionError(error) {
    console.log(error);
    this.isPlateRecognizerResponseLoading = false;
    this.error = error?.message || error;
  }

  private async getCompressedCroppedFile(): Promise<File> {
    const imageToCompress = await imageCompression.getFilefromDataUrl(this.croppedImage, "compressed.jpg");
    if (this.isBarcodeClicked) {
      return await this.compressBarcodeImageIfNeeded(imageToCompress);
    } else {
      return await this.compressPlateImageIfNeeded(imageToCompress);
    }
  }

  private async compressPlateImageIfNeeded(imageFile: File): Promise<File> {
    this.isPlateRecognizerResponsePopUpVisible = true;
    this.isPlateRecognizerResponseLoading = true;
    let options = {
      maxSizeMB: 3,
      maxWidthOrHeight: 1920
    };
    if (imageFile.size / 1024 / 1024 > 3) {
      return await imageCompression(imageFile, options);
    }
    return imageFile;
  }

  private async compressBarcodeImageIfNeeded(imageFile: File): Promise<File> {
    this.isBarcodeRecognizerResponsePopUpVisible = true;
    this.isPlateRecognizerResponseLoading = true;
    let options = {
      maxSizeMB: 3,
      maxWidthOrHeight: 1920
    };
    if (imageFile.size / 1024 / 1024 > 3) {
      return await imageCompression(imageFile, options);
    }
    return imageFile;
  }

  handleRetakePhoto() {
    this.active = true;
    this.webcamImage = undefined;
    this.isPlateRecognizerResponsePopUpVisible = false;
    this.isBarcodeRecognizerResponsePopUpVisible = false;
  }
}

