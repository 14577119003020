<app-container headerTitle="{{violationTypes && violationTypes.length > 0 ? 'Add violation' : ''}}" [colWidth]="8" cardClass="mb-5">
  <div class="side-note" *ngIf="violationTypes && violationTypes.length > 0">
    <span class="required">*</span>Required fields
  </div>
  <p class="h1 required" *ngIf="violationTypes && violationTypes.length == 0">You can't submit violation as long as violation types are absent</p>
  <form *ngIf="violationTypes && violationTypes.length > 0" [formGroup]="violationForm" (ngSubmit)="onSubmit()">

    <div class="vehicle-details">
      <h2>Vehicle details</h2>
      <div class="row form__row">
        <div class="plate-number col-sm-6 form-group">
          <label for="licensePlateNumber">License Plate Number <span class="required">*</span></label>
          <input type="text"
                 required
                 formControlName="licensePlateNumber"
                 class="form-control form-control-lg"
                 id="licensePlateNumber"
                 placeholder="XXXXXXX"
                 [ngClass]="{ 'is-invalid': submitted && form.licensePlateNumber.errors }">
          <div *ngIf="submitted && form.licensePlateNumber.errors" class="invalid-feedback">
            <div *ngIf="form.licensePlateNumber.errors.required">This field is required</div>
          </div>
        </div>
        <div class="state col-sm-3 form-group">
          <label>State <span class="required">*</span></label>
          <div class="select-container">
            <div #stateSelectButton class="main-select form-control form-control-lg " (click)="toggleStateDropdown()">
              <span class="state-name">{{stateName}}</span>
            </div>
            <div #stateContent class="select-content form-control form-control-lg"
                 *ngIf="contentStateDropdown">
              <ul class="option-list">
                <li class="option-item"
                    *ngFor="let stateCode of stateCodes"
                    (click)="selectState(stateCode)">
                  <span class="state-name">{{stateCode}}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="row form__row" *ngIf="showAdditionalFields">
        <div class="plate-type col-sm-6">
          <label>Plate Type <span class="required">*</span></label>
          <div class="row no-gutters row-cols-2">
            <div class="form-check col mb-3" *ngFor="let plateType of plateTypes">
              <input class="form-check-input radio-in-col"
                     type="radio"
                     required
                     (change)="onPlateTypeChange()"
                     formControlName="plateType"
                     id="plate-type-{{plateType}}"
                     value="{{plateType}}">
              <label class="form-check-label label-in-col" for="plate-type-{{plateType}}">
                {{plateType}}
              </label>
            </div>
            <div *ngIf="submitted && form.plateType.errors" class="error-message">
              <div *ngIf="form.plateType.errors.required">This field is required</div>
            </div>
            <div *ngIf="submitted && form.otherPlateType.errors" class="invalid-feedback">
              <div *ngIf="form.otherPlateType.errors.required">This field is required</div>
            </div>
          </div>
          <input type="text"
                 [ngClass]="{'form-control':true, 'd-none': violationForm.controls.plateType.value!=='Other', 'is-invalid': submitted && form.otherPlateType.errors}"
                 class="form-control form-control-lg col-12"
                 placeholder="Other Plate Type"
                 formControlName="otherPlateType"
          >
        </div>
        <div class="plate-color col-sm-3">
          <label>Plate Color <span class="required">*</span></label>
          <div class="mb-3" *ngFor="let plateColor of plateColors">
            <input class="form-check-input radio-in-col"
                   type="radio"
                   required
                   formControlName="plateColor"
                   id="plate-color-{{plateColor}}"
                   value="{{plateColor}}">
            <label class="form-check-label label-in-col" for="plate-color-{{plateColor}}">
              {{plateColor}}
            </label>
          </div>
          <div *ngIf="submitted && form.plateColor.errors" class="error-message">
            <div *ngIf="form.plateColor.errors.required">This field is required</div>
          </div>
        </div>
      </div>
      <div class="row form__row no-gutters start-time">
        <label class="start-time__label">Start time</label>
        <ngb-timepicker formControlName="time"></ngb-timepicker>
      </div>
      <div class="row form__row">
        <div class="make col-sm-6 form-group">
          <label>Make <span class="required">*</span></label>
          <div class="select-container">
            <div #makeSelectButton class="main-select form-control form-control-lg " (click)="toggleMakeDropdown()">
              <span class="state-name">{{makeName}}</span>
            </div>
            <div #makeContent class="select-content form-control form-control-lg"
                 *ngIf="contentMakeDropdown">
              <ul class="option-list">
                <li class="option-item"
                    *ngFor="let make of makeTypes"
                    (click)="selectMake(make)">
                  <span class="state-name">{{make}}</span>
                </li>
              </ul>
            </div>
            <input *ngIf="showMakeInput" type="text"
                   required
                   formControlName="make"
                   class="form-control form-control-lg mt-3"
                   id="make"
                   placeholder="Make">
          </div>
          <div *ngIf="submitted && form.make.errors" class="invalid-feedback">
            <div *ngIf="form.make.errors.required">This field is required</div>
          </div>
        </div>
        <div class="color col-sm-6 col-lg-3 form-group">
          <label>Color <span class="required">*</span></label>
          <div class="select-container">
            <div #colorSelectButton class="main-select form-control form-control-lg"
                 (click)="toggleColorDropdown()">
              <span class="color-preview color-{{colorName | lowercase}}"></span>
              <span class="color-name">{{colorName}}</span>
            </div>
            <div #colorContent class="select-content form-control form-control-lg"
                 *ngIf="contentColorDropdown">
              <ul class="option-list">
                <li class="option-item"
                    *ngFor="let color of colors"
                    (click)="selectColor(color)">
                  <span class="color-preview color-{{color | lowercase}}"></span>
                  <span class="color-name">{{color}}</span>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="submitted && form.color.errors" class="invalid-feedback">
            <div *ngIf="form.color.errors.required">This field is required</div>
            <div *ngIf="form.color.errors.maxlength">This field has to be at most 3 characters</div>
          </div>
        </div>
      </div>
      <div class="row form__row" [hidden]="true">
        <div class="model col-sm-6 form-group">
          <label for="model">Model</label>
          <input type="text"
                 class="form-control form-control-lg"
                 formControlName="model"
                 id="model"
                 placeholder="Model">
        </div>
        <div class="type col-sm-3 form-group">
          <label for="type">Type</label>
          <input type="text"
                 class="form-control form-control-lg"
                 formControlName="type"
                 id="type"
                 placeholder="Type">
        </div>
      </div>
    </div>
    <div class="violation-details">
      <h2>Violation details</h2>
      <div class="row form__row">
        <div class="location col-sm-6 form-group">
          <label for="location">Location <span class="required">*</span></label>
          <input type="text"
                 required
                 class="form-control form-control-lg"
                 formControlName="location"
                 id="location"
                 placeholder="Location"
                 [ngClass]="{'warning': form.location.errors?.maxlength,  'is-invalid': submitted && form.location.errors }">
          <small class="form-text text-muted"
                 [class.required]="form.location.errors?.maxlength">
            20 characters limit
          </small>
          <div *ngIf="submitted && form.location.errors" class="invalid-feedback">
            <div *ngIf="form.location.errors.required">This field is required</div>
          </div>
        </div>
        <div class="meter-number col-sm-6 form-group">
          <label for="meterNumber">Meter number</label>
          <input type="text"
                 class="form-control form-control-lg"
                 formControlName="meterNumber"
                 id="meterNumber"
                 placeholder="Meter Number"
                 [ngClass]="{ 'is-invalid': form.meterNumber.errors }">
          <div *ngIf="form.meterNumber.errors" class="invalid-feedback">
            <div *ngIf="form.meterNumber.errors.maxlength">This field must be less than 255 characters</div>
          </div>
        </div>
      </div>

      <div *ngIf="isLeo()" class="gps-details">
        <h2>GPS coordinates</h2>
        <div class="row form__row">
          <div class="col-sm-4 form-group">
            <label for="latitude">Latitude</label>
            <input type="text"
                   class="form-control form-control-lg"
                   formControlName="latitude"
                   id="latitude"
                   placeholder="Latitude"
                   [ngClass]="{ 'is-invalid': form.latitude.errors }">
            <div *ngIf="form.latitude.errors" class="invalid-feedback">
              <div *ngIf="form.latitude.errors.pattern">The latitude must be in correct format like 50.4021379</div>
            </div>
          </div>
          <div class="col-sm-4 form-group">
            <label for="longitude">Longitude</label>
            <input type="text"
                   class="form-control form-control-lg"
                   formControlName="longitude"
                   id="longitude"
                   placeholder="Longitude"
                   [ngClass]="{ 'is-invalid': form.longitude.errors }">
            <div *ngIf="form.latitude.errors" class="invalid-feedback">
              <div *ngIf="form.latitude.errors.pattern">The longitude must be in correct format like 30.3678856</div>
            </div>
          </div>
        </div>
      </div>

      <div class="form__row violations">
        <label>Violations <span class="required">*</span></label>
        <div formGroupName="violationCheckboxGroup">
          <div class="form-check violation-input" *ngFor="let violationType of violationTypes">
            <input class="form-check-input checkbox-input"
                   formControlName="{{violationType.id}}"
                   type="checkbox"
                   [value]="violationType.violationTypeName"
                   id="{{violationType.violationTypeCode}}">
            <label class="form-check-label checkbox-label" for="{{violationType.violationTypeCode}}">
              {{violationType.violationTypeCode}}
              - {{violationType.violationTypeName}} <span class="fine">{{violationType.violationTypeFine}}</span>
            </label>
            <ng-container *ngIf="isOtherViolationTypePresent(violationType)">
              <input type="text"
                     [ngClass]="{'form-control':true, 'd-none':!isOtherSelected, 'is-invalid': form.violationCheckboxGroup.get('otherViolationType').errors }"
                     class="form-control form-control-lg"
                     placeholder="Description of other violation"
                     formControlName="otherViolationType"
              >
              <div *ngIf="form.violationCheckboxGroup.get('otherViolationType').errors" class="error-message">
                <div *ngIf="form.violationCheckboxGroup.get('otherViolationType').errors.required">This field is
                  required
                </div>
              </div>
            </ng-container>
          </div>
          <div *ngIf="submitted && form.violationCheckboxGroup.errors" class="error-message">
            <div *ngIf="form.violationCheckboxGroup.errors.requireCheckboxesToBeChecked">You have to pick at least one
              violation type
            </div>
          </div>
        </div>
      </div>
      <div class="form__row">
        <label for="notes">Notes</label>
        <div class="form-group">
          <textarea formControlName="notes" id="notes"
                    placeholder="Violation notes"
                    class="form-control form-control-lg"
                    [ngClass]="{'form-control': true, 'is-invalid': submitted && form.notes.errors, 'warning': form.notes.errors}"></textarea>
          <small class="form-text text-muted"
                 [class.required]="form.notes.errors">
            50 characters limit
          </small>
        </div>
      </div>
      <div class="form__row">
        <ul class="added-photos">
          <li class="photo__item row no-gutters"
              *ngFor="let file of attachedPhotos; index as i">
            <button class="btn delete__btn" (click)="deleteFile(i)"></button>
            <div class="photo__name">{{file.name}}, {{getFileSizeStr(file.size)}}</div>
          </li>
        </ul>
      </div>
      <div class="form__row">
        <div class="container-md">
          <div class="add-photos row">
            <label for="file" class="add-photos__btn btn col-sm-3 col-lg-2">Add media</label>
            <input type="file" id="file" hidden multiple accept="{{ availableExtensions }}"
                   (change)="onFileSelected($event)">
            <div class="col-sm-9 col-lg-10 btn-description">Click to add photos or videos that show any unusual aspects of this
              violation that may be necessary to an appeals officer. Available extensions : {{ availableExtensions }}. Max file size : single file 8MB, total 32MB.
              Max files limit: 4 items.
            </div>
            <small *ngIf="!isAccessoryDataValid()" class="required mb-3">
              4 files limit
            </small>
          </div>
          <div class="add-notes row">
            <button type="button" class="col-sm-3 col-lg-2 btn add-notes__btn" (click)="showNotes()">Add notes</button>
            <div class="col-sm-9 col-lg-10 btn-description">Click to add notes that show any unusual aspects of this
              violation that may be necessary to an appeals officer
            </div>
          </div>
        </div>
      </div>
      <div class="form__row" *ngIf="showNotesField">
        <label for="photoNotes">Notes</label>
        <div class="form-group">
          <textarea formControlName="photoNotes" id="photoNotes"
                    placeholder="Notes that show any unusual aspects of this violation"
                    class="form-control form-control-lg"
                    [ngClass]="{'form-control': true, 'is-invalid': submitted && form.photoNotes.errors,'warning': form.photoNotes.errors }"></textarea>
          <small class="form-text text-muted"
                 [class.required]="form.photoNotes.errors">
            50 characters limit
          </small>
        </div>
      </div>
      <div class="agent-details">
        <h2>Enforcement Agent Details</h2>
        <div class="row form">
          <div class="badge-id col-sm-6">
            <label for="badgeId">Badge id <span class="required">*</span></label>
            <input type="text"
                   required
                   class="form-control form-control-lg"
                   formControlName="badgeId"
                   id="badgeId"
                   placeholder="Badge Id"
                   [ngClass]="{ 'is-invalid': form.badgeId.errors }" readonly>
            <div *ngIf="form.badgeId.errors" class="invalid-feedback">
              <div *ngIf="form.badgeId.errors.required">This field is required</div>
              <div *ngIf="form.badgeId.errors.min">This value has to be more than 0</div>
              <div *ngIf="form.badgeId.errors.max">This value has to be less than 2147483647</div>
            </div>
          </div>
          <div class="agent-name col-sm-6">
            <label for="name">Name <span class="required">*</span></label>
            <input type="text"
                   required
                   class="form-control form-control-lg"
                   formControlName="name"
                   id="name"
                   placeholder="Name"
                   [ngClass]="{ 'is-invalid': submitted && form.name.errors }" readonly>
            <div *ngIf="submitted && form.name.errors" class="invalid-feedback">
              <div *ngIf="form.name.errors.required">This field is required</div>
            </div>
          </div>
        </div>
      </div>
      <ngb-alert *ngIf="error" (close)="closeAlert()" class="mt-3" [type]="'danger'">{{ error }}</ngb-alert>
      <div class="form-controls row no-gutters">
        <button type="button" (click)="goBack()" class="btn btn-primary btn-cancel">Cancel</button>
        <button type="submit" class="ml-auto btn btn-primary btn-submit"
                [class.active]="!violationForm.invalid || isAccessoryDataValid()"
                [disabled]="loading || violationForm.invalid || !isAccessoryDataValid()">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Submit Violation
        </button>
      </div>
    </div>
  </form>
</app-container>
