<div *ngIf="chalkStatus?.chalkRecords?.length > 0 || chalkStatus?.violationRecords?.length > 0" class="row no-gutters chalk-match">
  <ng-container>
    <div class="chalk-match__message">
      <h1> MATCHES FOUND </h1>
      <p>The license plate you entered is {{ chalkStatus.licensePlateNumber | uppercase }},
        your coordinates are {{ chalkStatus.capturedLatitude }}, {{ chalkStatus.capturedLongitude }} </p>
      <br>
      <div *ngIf="chalkStatus?.chalkRecords?.length > 0"><p> Chalks : </p>
      <div *ngFor="let chalk of chalkStatus.chalkRecords">
        <p> - Vehicle <{{ chalk.licensePlateNumber }}> was recorded
          on {{ chalk.createDate | date :"yyyy-MM-dd hh:mm:ss a" }},
          GPS coordinates {{ chalk.latitude }}, {{ chalk.longitude }}</p>
      </div>
      </div>
      <div *ngIf="chalkStatus?.violationRecords?.length > 0"><p> Violations : </p>
      <div *ngFor="let violation of chalkStatus.violationRecords">
        <p> - Vehicle <{{ violation.licensePlateNumber }}> has been issued a violation at <{{ violation.location }}>
          on {{ violation.createDate | date :"yyyy-MM-dd hh:mm:ss a" }},
          {{ getViolationTypeNames(violation.violationTypes)}}
          {{ getCoordinates(violation)}}
          </p>
      </div>
      </div>

      <div class="chalk-match__btn-group">
        <button class="btn btn-continue" routerLink="/violation/submit">Create Violation for Entered Plate</button>
        <button class="btn btn-cancel-chalk" (click)="resetForm()">Cancel</button>
      </div>
    </div>
  </ng-container>
</div>

<div *ngIf="chalkStatus?.chalkRecords?.length === 0 && chalkStatus?.violationRecords?.length === 0">
  <div class="row no-gutters chalk-reply__wrapper" [ngClass]="'chalk-reply-no-found'">
    <span>No matches found</span>
    <button class="btn btn-submit active ml-auto" type="button" routerLink="/violation/submit">Add violation</button>
  </div>
  <div class="row no-gutters">
    <button type="button" (click)="nextVehicle()" class="btn btn-next-vehicle">Next vehicle</button>
  </div>
</div>

<div *ngIf="chalkStatus?.capturedLatitude === null || chalkStatus?.capturedLongitude === null">
  <div class="row no-gutters chalk-reply__wrapper" [ngClass]="'chalk-reply-no-found'">
    <span>Unable to chalk, GPS coordinates not captured, make sure geolocation is allowed on your device</span>
    <button class="btn btn-submit active ml-auto" type="button" routerLink="/violation/submit">Add violation</button>
  </div>
  <div class="row no-gutters">
    <button type="button" (click)="nextVehicle()" class="btn btn-next-vehicle">Next vehicle</button>
  </div>
</div>
