<app-container headerTitle={{headerTitle}}>
  <form class="form" [formGroup]="checkViolationForm" (ngSubmit)="onSubmit()" autocomplete="off">
    <div class="row plate-number justify-content-center">
      <div class="form-group col col-md-6">

        <div *ngIf="showZoneLocation">
          <div class="plate-number__feilds no-gutters" *ngIf="availableTownZones != null && availableTownZones.length > 0">
            <label for="zone-select">Select zone</label>
            <select class="form-control form-control-lg" (change)="onZoneChange($event)" name="zone" id="zone-select" formControlName="zone">
              <option [value]="0">No zone selected</option>
              <option *ngFor="let zone of availableTownZones" [value]="zone.zoneId">
                {{ zone.zoneNumber }}
              </option>
            </select>
          </div>

          <div class="plate-number__feilds no-gutters" *ngIf="selectedZone != null">
            <label for="zone-select">Location</label>
            <input class="form-control form-control-lg"
                   [value]="form.location.value"
                   name="location"
                   formControlName="location"
                   id="location">
          </div>
        </div>

        <div *ngIf="!showZoneLocation">
          <div class="plate-number__feilds no-gutters" *ngIf="availableTownZones != null && availableTownZones.length > 0">
            <label for="zone-select">Select zone</label>
            <select class="form-control form-control-lg" (change)="onZoneChange($event)" name="zone" id="zone-select" formControlName="zone">
              <option [value]="0">No zone selected</option>
              <option *ngFor="let zone of availableTownZones" [value]="zone.zoneId">
                {{ zone.zoneName }}
              </option>
            </select>
          </div>
        </div>

        <div class="row no-gutters plate-number__feilds">
          <div class="plate-number__input">
            <label for="plateNumber" class="larger-label mb-1">Plate number</label>
            <input class="form-control form-control-lg" #checkViolationInput autofocus
                   type="text"
                   id="plateNumber"
                   formControlName="plateNumber"
                   [value]="form.plateNumber.value | uppercase"
                   placeholder="Plate number"
                   [ngClass]="{ 'is-invalid': submitted && form.plateNumber.errors }">
            <div *ngIf="submitted && form.plateNumber.errors" class="invalid-feedback">
              <div *ngIf="form.plateNumber.errors.required">This field is required</div>
            </div>
          </div>
          <div class="plate-number__btn">
<!--            FOR USERS WHO DOESN'T USE LAPTOP-->
            <ng-container *ngIf="!isAllowedToUsePCWebCam(); else takeShotButtonForLeo">
              <label for="buttonTakePhoto" class="btn-take-photo" [class.inactive]="isCameraRecognizerDisabled()"></label>
              <input id="buttonTakePhoto" type="file" [disabled]="isCameraRecognizerDisabled()" accept="image/*"
                     (click)="handlePlateFileInputClick()" capture="environment" hidden
                     (change)="onFileChangePlate($event.target)"/>
            </ng-container>

<!--            FOR USERS WHO USE LAPTOP -->
            <ng-template #takeShotButtonForLeo>
              <label for="buttonTakePhotoLeo" class="btn-take-photo" [class.inactive]="isCameraRecognizerDisabled()"></label>
              <input id="buttonTakePhotoLeoAlt" type="file" [disabled]="isCameraRecognizerDisabled()" accept="image/*"
                     (click)="handlePlateFileInputClick()" capture="environment" hidden
                     (change)="onFileChangePlate($event.target)"/>
              <button id="buttonTakePhotoLeo" hidden (click)="togglePlateNgxWebcam()" type="button"
                      *ngIf="isAllowedToUsePCWebCam() && !isCameraRecognizerDisabled()"></button>
            </ng-template>
          </div>
          <div class="plate-number__btn">
            <!--            FOR USERS WHO DOESN'T USE LAPTOP-->
            <ng-container *ngIf="!isAllowedToUsePCWebCam(); else takeBarcodeShotButtonForLeo">
              <label for="buttonTakeBarcode" class="qr-scan__btn" [class.inactive]="isCamera2DBarcodeDisabled()"></label>
              <input id="buttonTakeBarcode" type="file" [disabled]="isCamera2DBarcodeDisabled()" accept="image/*"
                     (click)="handleBarcodeFileInputClick()" capture="environment" hidden
                     (change)="onFileChangeBarcode($event.target)"/>
            </ng-container>

            <!--            FOR USERS WHO USE LAPTOP -->
            <ng-template #takeBarcodeShotButtonForLeo>
              <label for="buttonTakeBarcodeLeo" class="qr-scan__btn" [class.inactive]="isCamera2DBarcodeDisabled()"></label>
              <input id="buttonTakeBarcodeLeoAlt" type="file" [disabled]="isCamera2DBarcodeDisabled()" accept="image/*"
                     (click)="handleBarcodeFileInputClick()" capture="environment" hidden
                     (change)="onFileChangeBarcode($event.target)"/>
              <button id="buttonTakeBarcodeLeo" hidden (click)="toggleBarcodeNgxWebcam()" type="button"
                      *ngIf="isAllowedToUsePCWebCam() && !isCamera2DBarcodeDisabled()"></button>
            </ng-template>
          </div>
        </div>

        <ng-container *ngIf="parkingStatus && !parkingStatus.fuzzyLogicParkingStatusCandidate">
          <app-parking-status [parkingStatus]="parkingStatus" (nextVehicleEvent)="onNextVehicle()"></app-parking-status>
        </ng-container>
        <ng-container *ngIf="parkingStatus?.fuzzyLogicParkingStatusCandidate">
          <app-possible-match [parkingStatus]="parkingStatus"
                              [plateNumber]="form.plateNumber.value"
                              (formReset)="resetForm()"
                              (plateMatched)="continueWithMatchedPlate()"
          ></app-possible-match>
        </ng-container>

        <ng-container *ngIf="chalkStatus">
          <app-chalk-status [chalkStatus]="chalkStatus" (formReset)="resetForm()" (nextVehicleEvent)="onNextVehicle()"></app-chalk-status>
        </ng-container>

        <div class="plate-number__button-group" [hidden]="parkingStatus || chalkStatus">
          <div class="row no-gutters button-group-static">
            <button type="button" class="btn btn-submit"
                    [disabled]="isPlateNumberInvalid()"
                    [class.active]="isPlateNumberValid()"
                    (click)="onAddViolationButtonClick()"
                    routerLink="/violation/submit">Add violation
            </button>
            <button *ngIf="isLeo()" type="button" class="btn btn-submit" style="margin-left: 10px"
                    [disabled]="isPlateNumberInvalid() || chalkLoading"
                    [class.active]="isPlateNumberValid()"
                    (click)="onChalkButtonClick()">
              <span *ngIf="chalkLoading" class="spinner-border spinner-border-sm mr-1"></span>
              Chalk
            </button>
            <button type="submit" class="btn btn-submit ml-auto"
                    [disabled]="isPlateNumberInvalid() || loading"
                    [class.active]="isPlateNumberValid()"
                    [hidden]=!isFlowbirdKioskAvailable()>
              <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
              Check
            </button>
          </div>
          <div class="row no-gutters" *ngIf="!form.plateNumber.errors">
            <button type="button" class="btn btn-cancel" (click)="resetForm()">Cancel</button>
          </div>
        </div>
        <div *ngIf="active && !webcamImage && (!isCameraRecognizerDisabled() || !isCamera2DBarcodeDisabled())">
          <div class="webcam__wrapper">
            <webcam [imageQuality]="1"
                    [width] = "540"
                    (initError)="handleInitError($event)"
                    [trigger]="triggerObservable"
                    (imageCapture)="handleImage($event)">
            </webcam>
          </div>
          <div class="row no-gutters button-group-static">
            <button class="btn btn-submit ml-auto active" (click)="triggerSnapshot();" type="button">Take a shot</button>
          </div>
        </div>
        <div class="snapshot" *ngIf="active && webcamImage && (!isCameraRecognizerDisabled() || !isCamera2DBarcodeDisabled())">
          <image-cropper
            [imageURL]="webcamImage.imageAsDataUrl"
            [maintainAspectRatio]="true"
            [aspectRatio]="4/3"
            format="png"
            (imageCropped)="imageCropped($event)"
            [transform]="transform"
          ></image-cropper>

          <input type="range" min="1" max="10" value="1" step="0.1" (input)="sliderInput($event)" class="slider" id="myRange">
          <div class="row no-gutters button-group-static">
            <button *ngIf="active && webcamImage" (click)="webcamImage = undefined" class="btn btn-submit active"
                    type="button">Retake a shot
            </button>
            <button *ngIf="active && webcamImage" class="btn btn-submit active ml-auto" type="button" (click)="uploadCropped()">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </form>
  <app-plate-recognizer-response-pop-up (closePopUpEvent)="hidePlateRecognizerResponsePopUp()"
                                        (continueEvent)="useRecognizedPlate()"
                                        [isLoading]="isPlateRecognizerResponseLoading"
                                        [error]="error"
                                        [isAllowedToUsePCWebCam]="isAllowedToUsePCWebCam()"
                                        (retakePhotoEvent)="handleRetakePhoto()"
                                        *ngIf="isPlateRecognizerResponsePopUpVisible"></app-plate-recognizer-response-pop-up>
  <app-barcode-recognizer-response-pop-up (closePopUpEvent)="hideBarcodeRecognizerResponsePopUp()"
                                        (continueEvent)="useRecognizedPlate()"
                                        [isLoading]="isPlateRecognizerResponseLoading"
                                        [error]="error"
                                        [isAllowedToUsePCWebCam]="isAllowedToUsePCWebCam()"
                                        (retakePhotoEvent)="handleRetakePhoto()"
                                        *ngIf="isBarcodeRecognizerResponsePopUpVisible"></app-barcode-recognizer-response-pop-up>
</app-container>
