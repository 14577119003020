import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing/app-routing.module';
import {NavbarComponent} from './components/navbar/navbar.component';
import {LoginComponent} from './components/login/login.component';
import {ContainerComponent} from './components/container/container.component';
import {ViolationComponent} from './components/violation/violation.component';
import {CheckViolationComponent} from './components/check-violation/check-violation.component';
import {SubmitViolationComponent} from './components/submit-violation/submit-violation.component';
import {ViolationTicketComponent} from './components/violation-ticket/violation-ticket.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {JwtInterceptor} from './auth/jwt.interceptor';
import {ErrorInterceptor} from './auth/error.interceptor';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { FooterComponent } from './components/footer/footer.component';
import { ParkingStatusComponent } from './components/check-violation/parking-status/parking-status.component';
import { PlateRecognizerResponsePopUpComponent } from './components/check-violation/plate-recognizer-response-pop-up/plate-recognizer-response-pop-up.component';
import { PossibleMatchComponent } from './components/check-violation/possible-match/possible-match.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { TownsListComponent } from './components/dashboard/towns-list/towns-list.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { TownAccountComponent } from './components/dashboard/town-account/town-account.component';
import { EndTextComponent } from "./components/dashboard/template/end-text.component";
import { ToastComponent } from "./components/global/toast-global.component";
import { UsersListComponent } from './components/dashboard/users-list/users-list.component';
import { ViolationsListComponent } from './components/dashboard/violations-list/violations-list.component';
import { TicketsListComponent } from './components/dashboard/tickets-list/tickets-list.component';
import { UserAccountComponent } from './components/dashboard/user-account/user-account.component';
import { ViolationDetailsComponent } from './components/dashboard/violation-details/violation-details.component';
import { TicketDetailsComponent } from './components/dashboard/ticket-details/ticket-details.component';
import { SafeHtmlPipe } from "./pipe/safe-html-pipe";
import { TownComponent } from './components/dashboard/town/town.component';
import { SecureImagePipe } from "./pipe/secure-image-pipe";
import { EmailManagementComponent } from './components/dashboard/email-management/email-management.component';
import { ConfirmationDialogComponent } from './components/dashboard/email-management/modal/confirmation-dialog/confirmation-dialog.component';
import { EditRecipientDialogComponent } from './components/dashboard/email-management/modal/edit-recipient-dialog/edit-recipient-dialog.component';
import {WebcamModule} from "ngx-webcam";
import {ImageCropperModule} from "ngx-image-cropper";
import {BarcodeRecognizerResponsePopUpComponent} from "./components/check-violation/barcode-recognizer-response-pop-up/barcode-recognizer-response-pop-up.component";
import * as Sentry from "@sentry/angular";
import {Router} from "@angular/router";
import {IpInterceptor} from "./auth/ip.interceptor";
import { ChalkStatusComponent } from './components/check-violation/chalk-status/chalk-status.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    LoginComponent,
    ContainerComponent,
    ViolationComponent,
    CheckViolationComponent,
    SubmitViolationComponent,
    ViolationTicketComponent,
    FooterComponent,
    ParkingStatusComponent,
    PlateRecognizerResponsePopUpComponent,
    BarcodeRecognizerResponsePopUpComponent,
    PossibleMatchComponent,
    DashboardComponent,
    TownsListComponent,
    TownAccountComponent,
    EndTextComponent,
    ToastComponent,
    SafeHtmlPipe,
    ToastComponent,
    UsersListComponent,
    ViolationsListComponent,
    TicketsListComponent,
    UserAccountComponent,
    ViolationDetailsComponent,
    TicketDetailsComponent,
    TownComponent,
    SecureImagePipe,
    EmailManagementComponent,
    ConfirmationDialogComponent,
    EditRecipientDialogComponent,
    ChalkStatusComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    NgbModule,
    HttpClientModule,
    FormsModule,
    AngularEditorModule,
    WebcamModule,
    ImageCropperModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: IpInterceptor, multi: true},
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
