import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ChalkStatus} from "../../../models/chalk-status";
import {PlateRecognizerResponseService} from "../../../services/plate-recognizer-response.service";
import {ViolationType} from "../../../models/violation-type";
import {ViolationRecord} from "../../../models/violation-record";

@Component({
  selector: 'app-chalk-status',
  templateUrl: './chalk-status.component.html',
  styleUrls: ['./chalk-status.component.css', '../../../app.component.scss',]
})
export class ChalkStatusComponent implements OnInit {

  @Input() chalkStatus: ChalkStatus;
  @Output() formReset = new EventEmitter();
  @Output() nextVehicleEvent = new EventEmitter();

  constructor(private plateRecognizerResponseService: PlateRecognizerResponseService) { }

  ngOnInit(): void {
  }

  resetForm() {
    this.resetCoordinates();
    this.plateRecognizerResponseService.resetNumberOfUnsuccessfulRecognizerAttempts();
    this.plateRecognizerResponseService.resetNumberOfUnsuccessfulBarcodeAttempts();
    this.formReset.emit(null);
  }

  nextVehicle() {
    this.resetCoordinates();
    this.nextVehicleEvent.emit(null);
  }

  // TODO move to geo-service
  resetCoordinates() {
    localStorage.removeItem('latitude');
    localStorage.removeItem('longitude');
  }

  getViolationTypeNames(violationTypes: ViolationType[]):string {
    return "<" + violationTypes.map(vt => vt.violationTypeName + " " + vt.violationTypeFine).join(",") + ">";
  }

  getCoordinates(violation: ViolationRecord) {
    return violation.latitude !== null && violation.longitude !== null ? `GPS coordinates ${violation.latitude},${violation.longitude} ` : ''
  }

}
