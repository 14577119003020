export const environment = {
  production: true,
  apiUrl: 'https://srv553037.hstgr.cloud/api',
  environment: 'dev',

  SENTRY_ORG: 'prkply',
  SENTRY_PROJECT: 'parking-system-web',
  SENTRY_AUTH_TOKEN: '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',

  ipCheckEnabled: true,

  dummyLatitude: '00.000000',
  dummyLongitude: '00.000000'
};
