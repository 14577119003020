import {Injectable} from "@angular/core";
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ChalkStatus} from "../models/chalk-status";

@Injectable({
  providedIn: 'root'
})
export class ChalkService {

  constructor(private http: HttpClient) {
  }

  // createNewChalk(townId: number, requestBody: object): Observable<ChalkStatus> {
  //   return this.http.post<ChalkStatus>(`${environment.apiUrl}/chalks/towns/${townId}`, requestBody);
  // }

  createNewChalkAndGetAllChalkAndViolations(townId: number, requestBody: object): Observable<ChalkStatus> {
    // return this.http.get<ChalkStatus>(`${environment.apiUrl}/chalks/towns/${townId}/plates/${plateNumber}?latitude=${latitude}&longitude=${longitude}`);
    return this.http.post<ChalkStatus>(`${environment.apiUrl}/chalks/towns/${townId}`, requestBody);
  }

}
